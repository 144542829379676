<template>
  <a-modal :class="$style.container" v-model="isShow" title="添加驿站" :width="500" @cancel="close" @ok="determine" :getContainer="_ => appRefs.app">
    <a-form-model ref="form" :class="$style.form__ct" :model="form" :rules="formRules" class="al-form" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }">
        <a-form-model-item label="驿站" prop="beInviteId">
         <a-select
                show-search
                :default-value="form.beInviteId"
                :key="form.beInviteId"
                placeholder="请选择驿站"
                style="width:250px"
                option-filter-prop="children"
                @change="handleChangeStage"
              >
              <a-select-option v-for="(item, index) in stageList" :key="index" :value="item.code">
                {{ item.name }}
              </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
  </a-modal>
</template>

<script>
export default {
  inject: ['appRefs'],
  data () {
    return {
      isShow: false,
      isDetermineLoading: false,
      stageList: [],
      form: {
        inviteId: '',
        beInviteId: '',
        inviteScene: ''
      },
      formRules: {
        beInviteId: [{ required: true, message: '请选择驿站', trigger: 'change' }]
      }
    }
  },
  created () {
  },
  methods: {
    // 设置表单
    setForm (form = {}) {
      this.form.inviteId = form.userId
      if (form.accountType === 'commune') {
        this.form.inviteScene = 'communeStage'
      } else if (form.accountType === 'stage') {
        this.form.inviteScene = 'stage'
      } else if (form.accountType === 'sale') {
        this.form.inviteScene = 'saleStage'
      }
      this.getStageList()
    },
    // 驿站列表
    async getStageList (val) {
      this.stageList = []
      const res = await this.$store.dispatch('http', {
        api: 'listByUnlistedStage',
        query: {
          inviteScene: this.form.inviteScene
        },
        params: {
          name: val
        }
      })
      if (res) {
        res.some((item, i) => {
          if (item.deptId === this.form.inviteId) {
            res.splice(i, 1)
            return true
          }
        })
        const that = this
        res.map(item => {
          that.stageList.push({ code: item.deptId, name: item.name })
        })
      }
    },
    handleChangeStage (e) {
      this.form.beInviteId = e
    },
    // 关闭
    close () {
      this.form.inviteId = ''
      this.form.beInviteId = ''
      this.$refs.form.resetFields()
    },
    // 确定
    async determine (e) {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.isDetermineLoading = true
          let api = 'addStage'
          await this.$store.dispatch('http', {
            api,
            query: {
              inviteScene: this.form.inviteScene,
              inviteId: this.form.inviteId,
              beInviteId: this.form.beInviteId
            },
            complete: () => {
              this.isDetermineLoading = false
            }
          })
          this.$store.dispatch('showToast', { message: '添加驿站成功~' })
          this.isShow = false
          this.close()
          this.$emit('save')
        }
      })
    }
  }
}
</script>

<style lang="scss" module>
.container {
  .form__ct {
    padding: 16px 0 10px;
  }
}
</style>
