<!--
 * @Descripttion: 面包导航
 * @version:
 * @Author: 高涛
 * @Date: 2021-10-12 11:58:15
 * @LastEditors: 高涛
 * @LastEditTime: 2021-11-04 10:23:06
-->
<template>
    <div class="flex-row" style="border-bottom:1px solid #eee; margin-bottom:20px; align-items: center;">
        <a-breadcrumb id="breadcrumb" class="flex-expand">
            <a-breadcrumb-item v-for="item in breadList" :key="item.name">
                <a v-if="item.changeParent && item.url!==''" @click="$utils.linkTo(item.url)">
                    {{item.name}}
                </a>
                <router-link v-else-if="item.url!==''" :to="item.url">
                    {{item.name}}
                </router-link>
                <span v-else>{{item.name}}</span>
            </a-breadcrumb-item>
        </a-breadcrumb>
        <slot></slot>
    </div>
</template>

<script>
export default {
  props: ['breadList']
}
</script>

<style lang="scss" scoped>
</style>
